import React, { Component } from "react";
import Header from "./Header";

class Submit extends Component {
  render() {
    return (
      <div className="flex min-h-full flex-col">
        <Header />
        <iframe
          src="https://form.jotform.com/240344209347049"
          title="JotForm Embed"
          style={{
            width: "100%",
            height: "100vh",
            border: "none",
            margin: "0",
            padding: "0",
          }}
          allowFullScreen
        ></iframe>
      </div>
    );
  }
}

export default Submit;
