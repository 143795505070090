import React, { Component } from "react";
import { FaExternalLinkAlt } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { REACT_APP_BACKEND_API_URL } from "utils/config";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Carousel } from "@material-tailwind/react";
import Header from "./Header";
import { Helmet } from "react-helmet";

function AboutToolWrapper() {
  const params = useParams();

  return <AboutTool params={params} />;
}

export default AboutToolWrapper;

class AboutTool extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      productImages: [],
      similarTools: [],
      tagsList: [],
      tool: [],
    };
  }

  shortenURL = (url) => {
    url = url.replace(/^https?:\/\/(www\.)?/, "");
    url = url.replace(/\.com\/.*$/, ".com");
    return url;
  };

  componentDidMount() {
    // Access the params from props
    const { params } = this.props;
    const slug = params.slug;
    fetch(`${REACT_APP_BACKEND_API_URL}/tool/${slug}`)
      .then((response) => response.json())
      .then((data) => {
        // Check if the productImages property exists
        const productImagesArray = data.tool.productImages
          ? data.tool.productImages.split(",")
          : [];

        // Check if the tags property exists
        const tagList = data.tool.tags ? data.tool.tags.split(",") : [];
        // Create a new tool object based on the fetched data
        const newToolData = {
          ...data.tool,
          productImages: productImagesArray,
          tags: tagList,
        };
        // Set the state with the new tool data
        this.setState({
          tool: newToolData,
          isLoading: false,
          otherTools: data.similarTools,
        });
      })
      .catch((error) => console.error("Error fetching data: ", error));
  }

  render() {
    const { isLoading, tool } = this.state;

    return (
      <div className="flex min-h-full flex-col">
        <Header />
        {isLoading ? (
          <div className="flex justify-center items-center h-full py-20">
            <div role="status">
              <svg
                aria-hidden="true"
                className="inline w-10 h-10 text-gray-200 animate-spin fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <main>
            <Helmet>
              <title>{tool.title} | FindDevTools</title>
              <meta name="description" content={tool.snippet} />
            </Helmet>
            <div className="max-w-xl mx-auto px-4 py-10 pb-20">
              <div className="mb-4 lg:mb-8">
                <div className="flex items-center justify-between mb-4">
                  <div className="flex-none mr-3 lg:mr-4 rounded-[22.5%] w-20 h-20 overflow-hidden">
                    <img
                      className="w-20 h-20"
                      src={tool.thumbnail}
                      alt={`Logo of ${tool.title}`}
                      loading="lazy"
                    ></img>{" "}
                  </div>

                  <div>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={tool.url}
                      style={{ display: "inline-flex", alignItems: "center" }}
                      className="rounded-full bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                      {this.shortenURL(tool.url)}
                      <FaExternalLinkAlt
                        style={{ display: "inline" }}
                        className="mx-1"
                      />
                    </a>
                  </div>
                </div>

                <div>
                  <h1 className="font-bold mb-0 text-3xl">{tool.title}</h1>
                  <div>
                    {tool.tags.map((tag, index) => (
                      <React.Fragment key={index}>
                        <div className="inline-flex items-center truncate">
                          <p className="text-gray-500 text-xs font-semibold truncate">
                            {tag}
                          </p>
                          {index < tool.tags.length - 1 && (
                            <span className="w-1 h-1 mx-1 bg-gray-500 rounded-full dark:bg-gray-400"></span>
                          )}
                        </div>
                      </React.Fragment>
                    ))}
                  </div>

                  <p className="text-sm text-gray-400">{tool.snippet}</p>
                </div>
              </div>
              <div></div>
              {(tool.productImages && (
                <Carousel className="rounded-xl">
                  {tool.productImages.map((productImage) => (
                    <div
                      className="image-wrapper"
                      style={{
                        position: "relative",
                        width: "100%",
                        paddingTop:
                          "56.25%" /* Example for 16:9 aspect ratio */,
                      }}
                    >
                      <img
                        src={productImage}
                        className="h-full w-full object-cover"
                        style={{ position: "absolute", top: 0, left: 0 }}
                        loading="lazy"
                        alt={`Screenshot of ${tool.title} Homepage`}
                      />
                    </div>
                  ))}
                </Carousel>
              )) || <h2>No images</h2>}

              {tool.features !== null && (
                <article className="">
                  <br></br>
                  {tool.features && (
                    <div>
                      <p className="underline font-bold text-3xl -mt-3 -mb-2">
                        Features of {tool.title}
                      </p>
                      <div className="markdown">
                        {/* markdown class is from tailwind  */}
                        <Markdown remarkPlugins={[remarkGfm]}>
                          {tool.features}
                        </Markdown>
                      </div>
                    </div>
                  )}

                  {tool.pricing && (
                    <div>
                      <p className="underline font-bold text-3xl">
                        Pricing options for {tool.title}
                      </p>
                      <div className="markdown">
                        <Markdown remarkPlugins={[remarkGfm]}>
                          {tool.pricing}
                        </Markdown>
                      </div>
                    </div>
                  )}
                </article>
              )}
              <div className="py-10">
                <h1 className="text-2xl py-2 font-bold">
                  Similar Tools We Recommend
                </h1>
                <div className="grid grid-cols-1 gap-4">
                  {this.state.otherTools.map((tool) => (
                    <a href={`/about/${tool.slug}`}>
                      <div
                        key={tool.id}
                        className="h-24 relative flex items-center space-x-3  bg-white px-2 py-4 border-b border-gray-200 "
                      >
                        <div className="flex-shrink-0">
                          <img
                            className="h-10 w-10 rounded-full"
                            src={tool.thumbnail}
                            alt={`Logo of ${tool.title}`}
                          />
                        </div>
                        <div className="min-w-0 flex-1">
                          <div className="focus:outline-none">
                            <span
                              className="absolute inset-0"
                              aria-hidden="true"
                            />
                            <p className="text-sm font-bold text-gray-900 truncate">
                              {tool.title}
                            </p>
                            <p
                              className="text-sm text-gray-500 overflow-hidden"
                              style={{
                                display: "-webkit-box",
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: "vertical",
                                maxHeight: "3rem",
                              }}
                            >
                              {tool.snippet}
                            </p>
                          </div>
                        </div>
                      </div>
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </main>
        )}
      </div>
    );
  }
}
