import React, { Component } from "react";
import { FaExternalLinkAlt } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { REACT_APP_BACKEND_API_URL } from "utils/config";
import InfiniteScroll from "react-infinite-scroll-component";
import Header from "./Header";

class Blog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      compareList: [],
      alternativeList: [],
      hasMoreAlternativeBlogPosts: true,
      toolsPage: 1,
    };
  }

  BookIcon(props) {
    return (
      <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M4 19.5v-15A2.5 2.5 0 0 1 6.5 2H20v20H6.5a2.5 2.5 0 0 1 0-5H20" />
      </svg>
    );
  }

  BookOpenIcon(props) {
    return (
      <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z" />
        <path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z" />
      </svg>
    );
  }

  BriefcaseIcon(props) {
    return (
      <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M16 20V4a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16" />
        <rect width="20" height="14" x="2" y="6" rx="2" />
      </svg>
    );
  }

  Columns2Icon(props) {
    return (
      <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <rect width="18" height="18" x="3" y="3" rx="2" />
        <path d="M12 3v18" />
      </svg>
    );
  }

  NewspaperIcon(props) {
    return (
      <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M4 22h16a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H8a2 2 0 0 0-2 2v16a2 2 0 0 1-2 2Zm0 0a2 2 0 0 1-2-2v-9c0-1.1.9-2 2-2h2" />
        <path d="M18 14h-8" />
        <path d="M15 18h-5" />
        <path d="M10 6h8v4h-8V6Z" />
      </svg>
    );
  }

  VideoIcon(props) {
    return (
      <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="m16 13 5.223 3.482a.5.5 0 0 0 .777-.416V7.87a.5.5 0 0 0-.752-.432L16 10.5" />
        <rect x="2" y="6" width="14" height="12" rx="2" />
      </svg>
    );
  }

  render() {
    return (
      <div className="flex min-h-full flex-col">
        <Header />
        <section className="bg-gray-100 dark:bg-gray-800 py-12 md:py-16 lg:py-20">
          <div className="container mx-auto px-4 md:px-6">
            <div className="max-w-3xl mx-auto text-center space-y-4">
              <h1 className="text-3xl md:text-4xl font-bold tracking-tight">
                FindDevTools Blog
              </h1>
              <p className="text-gray-500 dark:text-gray-400 text-lg">
                Discover the latest insights, tutorials, and news from the
                developer community.
              </p>
            </div>
          </div>
        </section>
        <section className="py-12 md:py-16 lg:py-20">
          <div className="container mx-auto px-4 md:px-6">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
              <Link
                className="group bg-white dark:bg-gray-950 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300"
                to={"/comparelist"}
              >
                <div className="flex items-center gap-4 p-4 sm:p-6">
                  <div className="bg-gray-100 dark:bg-gray-800 rounded-full p-2">
                    <this.Columns2Icon className="w-6 h-6 text-gray-500 dark:text-gray-400" />
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold">Compare Tools</h3>
                    <p className="text-gray-500 dark:text-gray-400 text-sm">
                      Find the best developer tools for your needs.
                    </p>
                  </div>
                </div>
              </Link>
              <Link
                className="group bg-white dark:bg-gray-950 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300"
                to={`/glossary`}
              >
                <div className="flex items-center gap-4 p-4 sm:p-6">
                  <div className="bg-gray-100 dark:bg-gray-800 rounded-full p-2">
                    <this.BookOpenIcon className="w-6 h-6 text-gray-500 dark:text-gray-400" />
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold">Glossary</h3>
                    <p className="text-gray-500 dark:text-gray-400 text-sm">
                      Understand the key terms in the developer world.
                    </p>
                  </div>
                </div>
              </Link>
              <Link
                className="group bg-white dark:bg-gray-950 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300"
                href="#"
              >
                <div className="flex items-center gap-4 p-4 sm:p-6">
                  <div className="bg-gray-100 dark:bg-gray-800 rounded-full p-2">
                    <this.VideoIcon className="w-6 h-6 text-gray-500 dark:text-gray-400" />
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold">Tutorials</h3>
                    <p className="text-gray-500 dark:text-gray-400 text-sm">
                      Learn new skills and techniques with our tutorials.
                    </p>
                  </div>
                </div>
              </Link>
              <Link
                className="group bg-white dark:bg-gray-950 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300"
                href="#"
              >
                <div className="flex items-center gap-4 p-4 sm:p-6">
                  <div className="bg-gray-100 dark:bg-gray-800 rounded-full p-2">
                    <this.NewspaperIcon className="w-6 h-6 text-gray-500 dark:text-gray-400" />
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold">News</h3>
                    <p className="text-gray-500 dark:text-gray-400 text-sm">
                      Stay up-to-date with the latest news and announcements.
                    </p>
                  </div>
                </div>
              </Link>
              <Link
                className="group bg-white dark:bg-gray-950 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300"
                href="#"
              >
                <div className="flex items-center gap-4 p-4 sm:p-6">
                  <div className="bg-gray-100 dark:bg-gray-800 rounded-full p-2">
                    <this.BriefcaseIcon className="w-6 h-6 text-gray-500 dark:text-gray-400" />
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold">Case Studies</h3>
                    <p className="text-gray-500 dark:text-gray-400 text-sm">
                      Learn how others are using our tools and services.
                    </p>
                  </div>
                </div>
              </Link>
              <Link
                className="group bg-white dark:bg-gray-950 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300"
                href="#"
              >
                <div className="flex items-center gap-4 p-4 sm:p-6">
                  <div className="bg-gray-100 dark:bg-gray-800 rounded-full p-2">
                    <this.BookIcon className="w-6 h-6 text-gray-500 dark:text-gray-400" />
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold">Guides</h3>
                    <p className="text-gray-500 dark:text-gray-400 text-sm">
                      Comprehensive guides to help you get started.
                    </p>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default Blog;
