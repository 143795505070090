import React, { Component } from "react";
import { Link } from "react-router-dom";
import { useParams, useLocation } from "react-router-dom";
import debounce from "lodash/debounce";
import { REACT_APP_BACKEND_API_URL } from "utils/config";
import InfiniteScroll from "react-infinite-scroll-component";
import { FaXTwitter } from "react-icons/fa6";
import { MdMailOutline } from "react-icons/md";
import Header from "./Header";
import { Helmet } from "react-helmet";

function HomeWrapper() {
  const params = useParams();
  const location = useLocation();

  return <Home params={params} location={location} />;
}
export default HomeWrapper;

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      categories: [],
      ads: [],
      allTools: [],
      categoryTools: [],
      currentCategory: -1,
      searchQuery: "",
      categoryQuery: "",
      selectedCategoryId: -1,
      hasMoreTools: true,
      toolsPage: 1,
      searchType: "all tools",
      pageTitle: this.homePageTitle,
      metaDesc: this.homePageMetaDesc,
    };
  }

  homePageTitle = "FindDevTools | Top Software Engineering Tools";
  homePageMetaDesc =
    "Discover the best software engineering tools on the internet. FindDevTools is the best place to discover new tools to improve your workflow.";

  onSearchChange = (event) => {
    if (this.currentCategory != -1) {
      this.handleToolSearchChange(event.target.value);
    }
    if (this.state.hasMoreTools) {
      this.loadToolsForSearch();
      this.setState({ hasMoreTools: false });
    }
  };

  loadToolsForSearch = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        tools_rendered: this.state.allTools.length,
      }),
    };
    const start = new Date().getTime();
    fetch(`${REACT_APP_BACKEND_API_URL}/tools-for-search`, requestOptions)
      .then((response) => response.json())
      //append data to this.state.tools
      .then((data) => {
        this.setState({
          allTools: [...this.state.allTools, ...data],
          hasMoreTools: false,
          isLoading: false,
        });
      })
      .catch((error) => console.error("Error fetching data: ", error))
      .finally(() => {
        // log time in milliseconds
        console.log(
          "Time in milliseconds to fetch remaining result: ",
          new Date().getTime() - start
        );
      });
  };

  handleToolSearchChange = debounce((searchValue) => {
    this.setState({ searchQuery: searchValue });
  }, 200);

  handleCategorySearchChange = (event) => {
    this.setState({ categoryQuery: event.target.value });
  };

  getFilteredCategories = () => {
    const { categories, categoryQuery } = this.state;
    if (categoryQuery === "") {
      return categories;
    }
    return categories.filter((category) => {
      return category.title.toLowerCase().includes(categoryQuery.toLowerCase());
    });
  };

  getFilteredTools = () => {
    if (this.state.currentCategory === -1) {
      const { allTools, searchQuery } = this.state;
      return allTools.filter((tool) => {
        const query = searchQuery.toLowerCase();
        return (
          tool.title.toLowerCase().includes(query) ||
          (tool.snippet && tool.snippet.toLowerCase().includes(query))
        );
      });
    } else {
      const { categoryTools, searchQuery } = this.state;
      return categoryTools.filter((tool) => {
        const query = searchQuery.toLowerCase();
        return (
          tool.title.toLowerCase().includes(query) ||
          (tool.snippet && tool.snippet.toLowerCase().includes(query))
        );
      });
    }
  };

  componentDidMount() {
    fetch(REACT_APP_BACKEND_API_URL + "/advertisements")
      .then((response) => response.json())
      .then((data) => {
        this.setState({ ads: data });
      })
      .catch((error) => console.error("Error fetching data: ", error));

    fetch(REACT_APP_BACKEND_API_URL + "/all-tags")
      .then((response) => response.json())
      .then((data) => {
        this.setState({ categories: data }, () => {
          this.updateCategory(); // Call here after categories are set
        });
      })
      .catch((error) => console.error("Error fetching data: ", error));

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ page_size: 100, page: this.state.toolsPage }),
    };
    if (this.state.selectedCategoryId === -1) {
      fetch(`${REACT_APP_BACKEND_API_URL}/all-tools`, requestOptions)
        .then((response) => response.json())
        .then((data) => this.setState({ allTools: data, isLoading: false }))
        .catch((error) => console.error("Error fetching data: ", error));
    }
  }

  fetchMoreTools = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        page_size: 100,
        page: this.state.toolsPage + 1,
      }),
    };

    fetch(`${REACT_APP_BACKEND_API_URL}/all-tools`, requestOptions)
      .then((response) => response.json())
      //append data to this.state.tools
      .then((data) => {
        if (data.length === 0) {
          this.setState({ hasMoreTools: false, isLoading: false });
        } else {
          this.setState({
            allTools: [...this.state.allTools, ...data],
            isLoading: false,
            toolsPage: this.state.toolsPage + 1,
          });
        }
      })
      .catch((error) => console.error("Error fetching data: ", error));
  };

  fetchCategoryTools = (categoryId) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ categoryID: categoryId }),
    };

    fetch(`${REACT_APP_BACKEND_API_URL}/category-tools`, requestOptions)
      .then((response) => response.json())
      //append data to this.state.tools
      .then((data) => {
        this.setState({
          categoryTools: [...data.content],
          isLoading: false,
        });
      })
      .catch((error) => console.error("Error fetching data: ", error));
  };

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.updateCategory();
    }
  }

  updateCategory() {
    const { params } = this.props;

    // If navigating directly, use the slug from the URL params
    if (params.categorySlug) {
      const categoryID = this.state.categories.find(
        (category) => category.slug === params.categorySlug
      );

      this.setState({
        currentCategory: categoryID.id,
        selectedCategoryId: categoryID.id,
        metaDesc: categoryID.snippet,
        pageTitle: `Top ${categoryID.title} Tools`,
      });

      this.fetchCategoryTools(categoryID.id);
    } else {
      this.setState({
        currentCategory: -1,
        selectedCategoryId: -1,
        metaDesc: this.homePageMetaDesc,
        pageTitle: this.homePageTitle,
      });
    }
  }

  tagsComponent({ tags }) {
    const tagList = tags.split(",").slice(0, 2);
    return (
      <div>
        {tagList.map((tag, index) => (
          <React.Fragment key={index}>
            <div className="inline-flex items-center truncate">
              <p className="text-gray-500 text-xs font-semibold truncate">
                {tag}
              </p>
              {index < tagList.length - 1 && (
                <span className="w-1 h-1 mx-1 bg-gray-500 rounded-full dark:bg-gray-400"></span>
              )}
            </div>
          </React.Fragment>
        ))}
      </div>
    );
  }

  getTools() {
    return this.getFilteredTools();
  }

  render() {
    const tools = this.getTools();
    const { isLoading, ads } = this.state;

    return (
      <div className="flex">
        <Helmet>
          <title>{this.state.pageTitle} | FindDevTools</title>
          <meta name="description" content={this.state.metaDesc} />
        </Helmet>
        <div className="flex-col border-r border-gray-300 w-80 hidden lg:block">
          <div className="border-b border-gray-200">
            <input
              type="text"
              className="border-none border-transparent focus:border-transparent focus:ring-0"
              onChange={this.handleCategorySearchChange}
              placeholder="Search Categories..."
            />
          </div>
          <div className="max-w-screen sticky top-0 max-h-screen overflow-x-scroll">
            <aside className="hidden lg:block">
              <ul className="px-2 py-2 ">
                <li
                  key={-1}
                  className={`border-b border-gray-200 h-8" ${
                    -1 === this.state.selectedCategoryId ? "bg-gray-100" : ""
                  }`}
                >
                  <Link to={`/`} onClick={() => this.updateCategory()}>
                    <button className="h-8 rounded-lg flex items-center py-1 pr-3 text-gray-700">
                      All
                    </button>
                  </Link>
                </li>
                {this.getFilteredCategories().map((category) => (
                  <Link
                    to={`/category/${category.slug}`}
                    state={{ categoryId: category.id }}
                  >
                    <li
                      key={category.id}
                      className={`border-b border-gray-200 h-8 ${
                        category.id === this.state.selectedCategoryId
                          ? "bg-gray-100"
                          : ""
                      }`}
                    >
                      {" "}
                      <button
                        onClick={() => this.updateCategory(category.id)}
                        className="py-1 pr-3 text-gray-700"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        {category.title}
                      </button>
                    </li>
                  </Link>
                ))}
              </ul>
            </aside>
          </div>
        </div>

        <div className="flex-col w-full">
          <div className="flex min-h-full flex-col">
            <Header />

            <div className="mx-auto flex w-full max-w-7xl items-start gap-x-8 px-4 py-10 sm:px-6 lg:px-8">
              <main className="flex-1">
                <div className="py-5">
                  {this.state.currentCategory === -1 ? (
                    <div>
                      <h1 className="text-2xl sm:text-3xl font-bold">
                        Where developers go for tools
                      </h1>

                      <div className="">
                        <p className="text-base">
                          Discover the next thing to 10x your workflow
                        </p>
                      </div>
                      <div className="py-2 flex items-center space-x-2">
                        <div className="">
                          <a
                            href="https://twitter.com/finddevtools"
                            className="inline-flex items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ring-1 ring-gray-200"
                          >
                            <FaXTwitter
                              className="-mr-0.5 h-5 w-5"
                              aria-hidden="true"
                            />
                            Follow on
                          </a>
                        </div>
                        <div className="">
                          <button
                            data-modal-target="authentication-modal"
                            data-modal-toggle="authentication-modal"
                            type="button"
                            className="inline-flex items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ring-1 ring-gray-200"
                          >
                            <MdMailOutline
                              className="-mr-0.5 h-5 w-5"
                              aria-hidden="true"
                            />
                            Subscribe
                          </button>

                          <div
                            id="authentication-modal"
                            tabindex="-1"
                            aria-hidden="true"
                            class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
                          >
                            <div class="relative p-4 w-full max-w-md max-h-full">
                              <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                                  <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                                    Subscribe to our Newsletter
                                  </h3>
                                  <button
                                    type="button"
                                    class="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                    data-modal-hide="authentication-modal"
                                  >
                                    <svg
                                      class="w-3 h-3"
                                      aria-hidden="true"
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 14 14"
                                    >
                                      <path
                                        stroke="currentColor"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                      />
                                    </svg>
                                    <span class="sr-only">Close modal</span>
                                  </button>
                                </div>

                                <div class="p-4 md:p-5">
                                  <iframe
                                    src="https://embeds.beehiiv.com/50556db8-9a57-462a-86a7-9e21c07e41c1"
                                    data-test-id="beehiiv-embed"
                                    width="100%"
                                    height="320"
                                    frameborder="0"
                                    scrolling="no"
                                    style={{
                                      borderRadius: "4px",
                                      border: "2px solid #e5e7eb",
                                      margin: "0",
                                      backgroundColor: "transparent",
                                    }}
                                  ></iframe>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <h1 className="text-2xl sm:text-3xl font-bold">
                        {this.state.pageTitle}
                      </h1>

                      <div className="">
                        <p className="text-base">{this.state.metaDesc}</p>
                      </div>
                    </div>
                  )}
                </div>
                <div className="">
                  <div className="py-5">
                    <label
                      htmlFor="default-search"
                      className="mb-2 text-sm font-medium text-gray-900 sr-only"
                    >
                      Search
                    </label>
                    <div className="relative">
                      <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                        <svg
                          className="w-4 h-4 text-gray-500"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 20 20"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                          />
                        </svg>
                      </div>
                      <input
                        type="search"
                        id="default-search"
                        className="block w-full p-3 ps-10 text-sm text-gray-900 border border-gray-300 rounded-xl bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
                        onChange={this.onSearchChange}
                        placeholder={`Searching ${this.state.searchType}...`}
                      ></input>
                    </div>
                  </div>
                  {isLoading ? (
                    <div className="flex justify-center items-center h-full py-20">
                      <div role="status">
                        <svg
                          aria-hidden="true"
                          className="inline w-10 h-10 text-gray-200 animate-spin fill-blue-600"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <InfiniteScroll
                      dataLength={tools.length}
                      next={this.fetchMoreTools}
                      pageStart={1}
                      hasMore={this.state.hasMoreTools}
                      loader={
                        <div>
                          {isLoading ? (
                            <div className="flex justify-center items-center h-full py-20">
                              <div role="status">
                                <svg
                                  aria-hidden="true"
                                  className="inline w-10 h-10 text-gray-200 animate-spin fill-blue-600"
                                  viewBox="0 0 100 101"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="currentFill"
                                  />
                                </svg>
                                <span className="sr-only">Loading...</span>
                              </div>
                            </div>
                          ) : (
                            <div></div>
                          )}
                        </div>
                      }
                    >
                      <div className="grid grid-cols-1 gap-3 sm:grid-cols-4">
                        {ads.map((ad) => (
                          <a
                            href={`${ad.url}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <div
                              key={ad.id}
                              className="h-24 relative items-center space-x-3 rounded-lg border border-2 border-gray-400 bg-white px-2 py-2 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400"
                            >
                              <div className="flex-grow">
                                <div className="flex items-center">
                                  <div className="flex-shrink-0">
                                    <img
                                      className="h-10 w-10 rounded-full"
                                      src={ad.thumbnail}
                                      alt={`Logo of ${ad.title}`}
                                    />
                                  </div>
                                  <div className="min-w-0 flex-1">
                                    <div className="focus:outline-none">
                                      <span
                                        className="absolute inset-0"
                                        aria-hidden="true"
                                      />
                                      <p className="text-sm font-bold text-gray-900 truncate">
                                        {ad.title}
                                      </p>
                                      <p
                                        className="text-sm text-gray-500 overflow-hidden"
                                        style={{
                                          display: "-webkit-box",
                                          WebkitLineClamp: 2,
                                          WebkitBoxOrient: "vertical",
                                          maxHeight: "3rem",
                                        }}
                                      >
                                        {ad.snippet}
                                      </p>
                                    </div>
                                  </div>
                                  <div class="absolute -bottom-2 right-3 sm:flex flex items-center space-x-1 z-50">
                                    <div class="flex items-center space-x-1 px-1.5 py-0.5 bg-gray-100 text-white rounded-full">
                                      <div class="font-medium text-xs text-gray-600">
                                        Sponsor
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </a>
                        ))}
                        <a
                          href={`/advertise`}
                          className="relative z-40  overflow-visible "
                        >
                          <div className="h-24 relative items-center space-x-3 rounded-lg border border-2 border-gray-400 bg-white px-2 py-2 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400">
                            <div className="flex-grow">
                              <div className="flex items-center">
                                <h3 className="font-bold truncate">
                                  Boost Your Tool's Visibility
                                </h3>
                                <div class="absolute -bottom-2 right-3 sm:flex flex items-center space-x-1 z-50">
                                  <div class="flex items-center space-x-1 px-1.5 py-0.5 bg-gray-100 text-white rounded-full">
                                    <div class="font-medium text-xs text-gray-600">
                                      Sponsor
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <p className="text-sm font-small truncate text-gray-900">
                                Get featured on FindDevTools
                              </p>
                            </div>
                          </div>
                        </a>

                        {tools.map((tool) => (
                          <a href={`/about/${tool.slug}`}>
                            <div
                              key={tool.id}
                              className="h-24 relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-2 py-2 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400"
                            >
                              <div className="flex-shrink-0">
                                <img
                                  className="h-10 w-10 rounded-full"
                                  src={tool.thumbnail}
                                  alt={`Logo of ${tool.title}`}
                                />
                              </div>
                              <div className="min-w-0 flex-1">
                                <div className="focus:outline-none">
                                  <span
                                    className="absolute inset-0"
                                    aria-hidden="true"
                                  />
                                  <p className="text-sm font-bold text-gray-900 truncate">
                                    {tool.title}
                                  </p>
                                  <p
                                    className="text-sm text-gray-500 overflow-hidden"
                                    style={{
                                      display: "-webkit-box",
                                      WebkitLineClamp: 2,
                                      WebkitBoxOrient: "vertical",
                                      maxHeight: "3rem",
                                    }}
                                  >
                                    {tool.snippet}
                                  </p>
                                </div>
                                <div className="truncate">
                                  {this.tagsComponent({ tags: tool.Tags })}
                                </div>
                              </div>
                            </div>
                          </a>
                        ))}
                      </div>{" "}
                    </InfiniteScroll>
                  )}
                </div>
              </main>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
