import React, { Component } from "react";
import { FaExternalLinkAlt } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { REACT_APP_BACKEND_API_URL } from "utils/config";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import Header from "./Header";

export class Advertise extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  scrollToDiv = (e) => {
    e.preventDefault(); // Prevent the default anchor link behavior
    const targetElement = document.getElementById("prices");

    // Check if the element exists to avoid errors
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  render() {
    return (
      <div className="flex min-h-full flex-col">
        <Header />
        <div className="px-6 sm:px-6 sm:py-10 lg:px-8">
          <div className="mx-auto max-w-2xl text-center pb-10 border-b border-gray-200">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Advertise your Developer Tool on FindDevTools
            </h2>
            <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">
              Reach tens of thousands of developers and drive traffic to your
              site.
              <br></br>
              See our public website stats on Similarweb.<br></br>
              <a
                href="https://www.similarweb.com/website/finddev.tools"
                className="text-sm sm:text-base underline"
                target="_blank"
              >
                https://www.similarweb.com/website/finddev.tools
              </a>
            </p>
          </div>
        </div>
        <div className="px-6" id="prices">
          <div
            // className="px-6 isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 sm:grid-cols-2 lg:max-w-none lg:justify-center"
            className="mx-auto grid max-w-md grid-cols-1 gap-8 pb-8 lg:max-w-3xl lg:grid-cols-2"
          >
            <div className="rounded-3xl py-8 xl:p-10 ring-1 ring-gray-200">
              <div className="items-center justify-between gap-x-4">
                <div className="">
                  <h3 className="text-center text-3xl text-black font-bold leading-8 ">
                    Premium Listing
                  </h3>
                  <h2 className="text-center text-xl  text-indigo-500 text-black font-bold">
                    $99 / month
                  </h2>
                </div>
                <div>
                  <ul class="list-disc">
                    <li className="text-md font-semibold py-2">
                      {" "}
                      Listing in one of the first four spots of every page
                    </li>
                    <li className="text-md font-semibold py-2">
                      Highlighted border on card
                    </li>
                    <li className="text-md font-semibold py-2">
                      Link Tracking
                    </li>
                    <li className="text-md font-semibold py-2">
                      Custom Call to Action on the card
                    </li>
                  </ul>
                  <p className="py-8 text-center text-sm font-semibold py-2 text-gray-400">
                    Billed Monthly. Cancel anytime.
                  </p>
                </div>
                <div className="py-20 mx-auto min-w-2xl text-center">
                  <a
                    type="button"
                    href="https://buy.stripe.com/7sI4if9Rc35I1WMaEH"
                    class="rounded-md bg-indigo-500 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                  >
                    <button
                      type="button"
                      className="rounded-md bg-indigo-500 px-3.5 py-2.5 text-lg font-bold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                    >
                      Get Started
                    </button>
                  </a>
                </div>
              </div>
            </div>
            <div className="rounded-3xl p-8 xl:p-10 ring-1 ring-gray-200">
              <div className="items-center justify-between gap-x-4">
                <h3 className="text-center text-3xl text-black font-bold leading-8 ">
                  Custom
                </h3>
                <h4 className="text-center text-md font-semibold py-2">
                  Interested in being featured in our newletter or a custom Ad?
                </h4>
                <div className="py-20 mx-auto max-w-2xl text-center">
                  {" "}
                  <a href="mailto:alexrpreston@gmail.com">
                    <button
                      type="button"
                      className="rounded-md bg-indigo-500 px-3.5 py-2.5 text-lg font-bold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                    >
                      Contact Us
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
