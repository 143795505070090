import React from "react";
import { Link } from "react-router-dom";

function Header() {
  return (
    <header className="shrink-0 border-b border-gray-200 bg-white">
      <div className="mx-auto flex h-16 max-w-7xl items-center justify-between px-4 sm:px-6 lg:px-8">
        <Link to={`/`} aria-label="Homepage">
          <div className="flex items-center">
            <img
              className="h-8 w-auto"
              src="https://ucarecdn.com/5be5086f-a1ff-4b50-be64-f8ec769a6bde/"
              alt="FinDevTools Logo"
            />
            <span className="font-bold px-2 py-1 text-md sm:text-2xl">
              Find Dev Tools
            </span>
          </div>
        </Link>
        <nav>
          <ul className="flex items-center gap-x-8">
            <li>
              <Link
                to={`/blog`}
                className="py-1 text-xs sm:text-lg font-semibold"
              >
                Blog
              </Link>
            </li>
            <li>
              <Link
                to={`/submit`}
                className="py-1 text-xs sm:text-lg font-semibold"
              >
                Submit
              </Link>
            </li>
            <li>
              <Link
                to={`/advertise`}
                className="py-1 text-xs sm:text-lg font-semibold"
              >
                Advertise
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Header;
