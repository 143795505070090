import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import AboutToolWrapper from "./pages/AboutTool";
import HomeWrapper from "./pages/Home";
import CompareWrapper from "pages/Compare";
import GlossaryList from "pages/GlossaryList";
import CompareList from "pages/CompareList";
import Blog from "pages/Blog";
import Submit from "pages/Submit";
import { Advertise } from "pages/Advertise";
import GlossaryWrapper from "pages/GlossaryDefinition";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomeWrapper />,
  },
  {
    path: "/about/:slug",
    element: <AboutToolWrapper />,
  },
  {
    path: "/category/:categorySlug",
    element: <HomeWrapper />,
  },
  {
    path: "/blog",
    element: <Blog />,
  },
  {
    path: "/compare/:slug",
    element: <CompareWrapper />,
  },
  {
    path: "/comparelist",
    element: <CompareList />,
  },
  {
    path: "/glossary",
    element: <GlossaryList />,
  },
  {
    path: "/glossary/:slug",
    element: <GlossaryWrapper />,
  },
  {
    path: "/submit",
    element: <Submit />,
  },
  {
    path: "/advertise",
    element: <Advertise />,
  },
  {
    path: "/robots.txt",
    element: <robots />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
reportWebVitals();
