import React, { Component } from "react";
import { REACT_APP_BACKEND_API_URL } from "utils/config";
import Header from "./Header";
import { Link } from "react-router-dom";

class GlossaryList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      GlossaryList: [],
      alternativeList: [],
      hasMoreAlternativeBlogPosts: true,
      toolsPage: 1,
    };
  }

  componentDidMount() {
    fetch(`${REACT_APP_BACKEND_API_URL}/all-tags-for-glossary`)
      .then((response) => response.json())
      .then((data) => this.setState({ GlossaryList: data, isLoading: false }))
      .catch((error) => console.error("Error fetching data: ", error));
  }

  render() {
    const { isLoading, GlossaryList } = this.state;

    return (
      <div className="flex min-h-full flex-col">
        <Header />
        <section className="bg-gray-100 dark:bg-gray-800 py-12 md:py-16 lg:py-20">
          <div className="container mx-auto px-4 md:px-6">
            <div className="max-w-3xl mx-auto text-center space-y-4">
              <h1 className="text-3xl md:text-4xl font-bold tracking-tight">
                Glosary of Developer Terms
              </h1>
              <p className="text-gray-500 dark:text-gray-400 text-lg">
                Understand the key terms in the developer world.
              </p>
            </div>
          </div>
        </section>
        <section className="py-12 md:py-16 lg:py-20">
          <div className="container mx-auto px-4 md:px-6">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
              {GlossaryList.map((tag) => (
                <Link
                  className="mt-4 hover:underline"
                  variant="link"
                  to={`/glossary/${tag.glossary_slug}`}
                >
                  <div className="p-4 group bg-white dark:bg-gray-950 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
                    <div>
                      <h3 className="text-lg py-2 font-semibold">
                        {tag.title}
                      </h3>
                      <p className="text-gray-500 dark:text-gray-400 text-sm line-clamp-3">
                        {tag.glossary_meta}
                      </p>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default GlossaryList;
