import React, { Component } from "react";
import { REACT_APP_BACKEND_API_URL } from "utils/config";
import Header from "./Header";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

class CompareList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      compareList: [],
      alternativeList: [],
      hasMoreAlternativeBlogPosts: true,
      toolsPage: 1,
    };
  }

  componentDidMount() {
    fetch(`${REACT_APP_BACKEND_API_URL}/all-tags`)
      .then((response) => response.json())
      .then((data) => this.setState({ compareList: data, isLoading: false }))
      .catch((error) => console.error("Error fetching data: ", error));
  }

  render() {
    const { isLoading, compareList } = this.state;

    return (
      <div className="flex min-h-full flex-col">
        <Helmet>
          <title>Comparison Developer Tools | FindDevTools</title>
          <meta
            name="description"
            content="Discover comprehensive lists of developer tools with in-depth comparisons and contrasts. Explore features, benefits, and use cases to find the perfect tools for your development needs. Stay updated with the latest and most effective tools in the industry, all in one place."
          />
        </Helmet>
        <Header />
        <section className="bg-gray-100 dark:bg-gray-800 py-12 md:py-16 lg:py-20">
          <div className="container mx-auto px-4 md:px-6">
            <div className="max-w-3xl mx-auto text-center space-y-4">
              <h1 className="text-3xl md:text-4xl font-bold tracking-tight">
                Compare Developer Tools
              </h1>
              <p className="text-gray-500 dark:text-gray-400 text-lg">
                Find the best tools to boost your productivity and streamline
                your development workflow.
              </p>
            </div>
          </div>
        </section>
        <section className="py-12 md:py-16 lg:py-20">
          <div className="container mx-auto px-4 md:px-6">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
              {compareList.map((tag) => (
                <div className="p-4 group bg-white dark:bg-gray-950 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
                  <div>
                    <div className="bg-gray-100 dark:bg-gray-800 rounded-full p-2">
                      <div className="w-6 h-6 text-gray-500 dark:text-gray-400" />
                    </div>
                  </div>
                  <div>
                    <h3 className="text-lg py-2 font-semibold">{tag.title}</h3>
                    <p className="text-gray-500 dark:text-gray-400 text-sm line-clamp-3">
                      {tag.snippet}
                    </p>
                    <Link
                      className="mt-4 hover:underline"
                      variant="link"
                      to={`/compare/${tag.slug}`}
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default CompareList;
